import { getMilitaryTime, LoadingPage } from '@chiroup/components';
import { convertToMS } from '@chiroup/core/functions/convertToMS';
import {
  AppointmentForUI,
  RecurringAppointmentData,
  RecurringAvailability,
} from '@chiroup/core/types/Appointment.type';
import { Discipline } from '@chiroup/core/types/Discipline.type';
import { useForm } from '@chiroup/hooks';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import qs from 'query-string';
import { useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MeContext } from '../../contexts/me.context';
import { ToastContext, ToastTypes } from '../../contexts/toast.context';
import Button, { ButtonColors } from '../common/Button';
import ScheduleRecurringAppointmentsForm from './ScheduleRecurringAppointmentsForm';

type Props = {
  appointment?: AppointmentForUI | null;
  onClose: () => void;
  checkRecurringAvailability: (
    appointmentUsedToCreateRecurringTimeStamp: number,
    appointment: Partial<AppointmentForUI>,
  ) => Promise<RecurringAvailability>;
  saveRecurringAppointments: (
    data: RecurringAppointmentData,
    locationId: number,
    sessionId: string,
    notify: boolean,
  ) => Promise<void>;
  updateRecurringAppointments?: (
    data: RecurringAppointmentData,
    originalAppointment: AppointmentForUI,
    locationId: number,
    sessionId: string,
    notify: boolean,
  ) => Promise<void>;
  recurringData: RecurringAvailability;
  setRecurringData: React.Dispatch<React.SetStateAction<RecurringAvailability>>;
  sessionId: string;
  editType?: string;
  setEditType?: React.Dispatch<React.SetStateAction<string>>;
  showForm?: boolean;
  setShowForm?: React.Dispatch<React.SetStateAction<boolean>>;
  timezone: string;
  passedDisciplines?: Discipline[];
};

const ScheduleRecurringAppointments: React.FC<Props> = ({
  onClose,
  checkRecurringAvailability,
  recurringData,
  setRecurringData,
  sessionId,
  saveRecurringAppointments,
  editType,
  setEditType,
  showForm,
  setShowForm,
  appointment,
  updateRecurringAppointments,
  timezone,
  passedDisciplines,
}) => {
  const { createToast } = useContext(ToastContext);
  const { selectedLocationFull } = useContext(MeContext);
  const [fetchingRecurringData, setFetchingRecurringData] = useState(false);
  const [idsToBookAppointments, setIdsToBookAppointments] = useState<{
    [key in number]: boolean;
  }>({});
  const { registerSubmit, isSubmitting } = useForm({});
  const { search } = useLocation();
  const { startTime: timeStamp, id: appointmentId } = qs.parse(search) as any;

  const checkAvailability = async (value: Partial<AppointmentForUI>) => {
    const params = qs.parse(search) as {
      startTime: string;
    };
    let startTime = params?.startTime ? Number(params.startTime) : 0;
    setFetchingRecurringData(true);
    if (!startTime && value.timeOfDay) {
      const timeOfDay = getMilitaryTime(value.timeOfDay);
      const [hour, minutes] = timeOfDay.split(':');
      startTime =
        appointment?.startTime
          .hour(Number(hour))
          ?.minute(Number(minutes))
          ?.valueOf() || 0;
    }
    if (startTime || appointment?.startTime) {
      const valueToSend = {
        ...value,
        timeOfDay: getMilitaryTime(value?.timeOfDay || ''),
      };
      const res = await checkRecurringAvailability(startTime, valueToSend);
      setRecurringData(res);
    }
    setFetchingRecurringData(false);
  };

  const onSubmitUpdateRecurring = async (notify: boolean) => {
    if (!Object.keys(idsToBookAppointments)?.length) return;
    const id = appointmentId;
    const timeStampsOfAppointments =
      recurringData?.dataWithAllConflicts?.reduce(
        (
          arr: {
            date: string;
            end: number;
            start: number;
          }[],
          appt,
        ) => {
          if (idsToBookAppointments[appt?.id]) {
            arr.push({
              date: appt.timestamp.date,
              end: appt.timestamp.end,
              start: appt.timestamp.start,
            });
          }
          return arr;
        },
        [],
      );
    if (!timeStampsOfAppointments?.length) return;
    const dataToSend = {
      originalAppointmentUsedId: id,
      recurringId: appointment?.recurringAppointmentId,
      duration: recurringData?.duration,
      patientId: recurringData?.patientId,
      treatmentId: recurringData?.treatmentId,
      clinicianId: recurringData?.clinicianId,
      timestamps: timeStampsOfAppointments,
      displayValues: recurringData?.displayValues,
    };
    if (appointment) {
      return updateRecurringAppointments?.(
        dataToSend,
        appointment,
        selectedLocationFull.ID || -1,
        sessionId,
        notify,
      );
    }
  };

  const onSubmit = async (notify: boolean) => {
    if (!Object.keys(idsToBookAppointments)?.length) return;
    const { id } = qs.parse(search) as any;
    const timeStampsOfAppointments =
      recurringData?.dataWithAllConflicts?.reduce(
        (
          arr: {
            date: string;
            end: number;
            start: number;
          }[],
          appt,
        ) => {
          if (idsToBookAppointments[appt?.id]) {
            arr.push({
              date: appt.timestamp.date,
              end: appt.timestamp.end,
              start: appt.timestamp.start,
            });
          }
          return arr;
        },
        [],
      );
    if (!timeStampsOfAppointments?.length) return;

    const dataToSend = {
      originalAppointmentUsedId: id,
      duration: recurringData?.duration,
      patientId: recurringData?.patientId,
      treatmentId: recurringData?.treatmentId,
      clinicianId: recurringData?.clinicianId,
      timestamps: timeStampsOfAppointments,
      displayValues: recurringData?.displayValues,
    };

    return saveRecurringAppointments(
      dataToSend,
      selectedLocationFull.ID || -1,
      sessionId,
      notify,
    );
  };

  const onSuccess = () => {
    onClose();
    createToast({
      title: 'Success!',
      description: <>Successfully created appointments!</>,
      type: ToastTypes.Success,
      duration: 5000,
    });
    setTimeout(() => {
      setRecurringData({});
      setIdsToBookAppointments({});
    }, 500);
  };

  const onFail = (e: any) => {
    onClose();
    createToast({
      title: 'Error!',
      description: <>Failed to create appointments!</>,
      type: ToastTypes.Fail,
      duration: 5000,
    });
    setTimeout(() => {
      setRecurringData({});
      setIdsToBookAppointments({});
    }, 500);
  };

  const allAvailableIds = useMemo(() => {
    return recurringData?.dataWithAllConflicts?.reduce(
      (obj: { [key in number]: boolean }, appt) => {
        if (
          !appt?.messages?.overrideMessage &&
          !appt?.messages?.clinicLocationMessage &&
          !appt?.messages?.providerMessage
        ) {
          obj[appt.id] = true;
        }
        return obj;
      },
      {},
    );
  }, [recurringData?.dataWithAllConflicts]);

  return fetchingRecurringData ? (
    <LoadingPage />
  ) : (
    <div>
      {!recurringData?.dataWithAllConflicts ? (
        <ScheduleRecurringAppointmentsForm
          checkAvailability={checkAvailability}
          onClose={onClose}
          fetchingRecurringData={fetchingRecurringData}
          editType={editType}
          setEditType={setEditType}
          showForm={showForm}
          setShowForm={setShowForm}
          appointment={appointment}
          timezone={timezone}
          passedDisciplines={passedDisciplines}
        />
      ) : (
        <div>
          <div className="text-xl flex flex-row gap-2 font-bold text-gray-900 dark:text-white sm:text-2xl relative justify-between">
            <Button
              onClick={() => {
                setIdsToBookAppointments({});
                setRecurringData({});
              }}
              text="Back"
              className="mb-4"
              icon={<ArrowLeftIcon className="w-4 h-4" />}
            />

            {Object.keys(allAvailableIds || {})?.length ===
              Object.keys(idsToBookAppointments)?.length &&
            !!recurringData?.dataWithAllConflicts?.length ? (
              <Button
                onClick={() => setIdsToBookAppointments({})}
                text="Uncheck all available"
                className="mb-4"
                disabled={Object.keys(allAvailableIds || {})?.length === 0}
              />
            ) : recurringData?.dataWithAllConflicts?.length ? (
              <Button
                onClick={() => setIdsToBookAppointments(allAvailableIds || {})}
                text="Check all available"
                className="mb-4"
              />
            ) : null}
          </div>
          {!recurringData?.dataWithAllConflicts?.length && (
            <p className="text-center">No possible dates available.</p>
          )}
          <form>
            {recurringData?.dataWithAllConflicts?.map((appt) => {
              const dt = dayjs(convertToMS(appt?.timestamp?.start)).tz(
                selectedLocationFull?.timezone || 'America/New_York',
              );
              const dayName = dt.format('dddd');
              const monthName = dt.format('MMMM');
              const dayNumber = dt.format('D');
              const year = dt.format('YYYY');
              const start = dt.format('h:mm A');
              const end = dt
                .add(recurringData?.duration || 0, 'minute')
                .format('h:mm A');
              if (
                appt?.messages?.overrideMessage ||
                appt?.messages?.clinicLocationMessage ||
                appt?.messages?.providerMessage
              ) {
                return (
                  <div
                    className="border-yellow-300 bg-yellow-300 p-4 rounded-lg text-sm"
                    key={appt.timestamp.start}
                  >
                    <p className="font-bold text-sm">
                      {recurringData?.displayValues?.patientName}
                    </p>
                    <div className="text-sm">{`${dayName} - ${monthName} ${dayNumber}, ${year}`}</div>
                    <div className="text-sm">{`${start} - ${end}`}</div>
                    <p className="font-bold">
                      <span className="text-sm">
                        {' '}
                        Appointment not available
                      </span>
                    </p>
                    <ul className="list-disc ml-4 text-sm">
                      {appt?.messages?.overrideMessage && (
                        <li>{appt?.messages?.overrideMessage}</li>
                      )}
                      {appt?.messages?.clinicLocationMessage && (
                        <li>{appt?.messages?.clinicLocationMessage}</li>
                      )}
                      {appt?.messages?.providerMessage && (
                        <li>{appt?.messages?.providerMessage}</li>
                      )}
                    </ul>
                  </div>
                );
              }
              return (
                <div
                  className="p-4 border border-gray-300 dark:bg-darkGray-700 rounded-lg cursor-pointer hover:bg-gray-100 dark:hover:bg-darkGray-700 hover:border-gray-300"
                  key={appt.timestamp.start}
                >
                  <div className="flex relative">
                    <span>
                      <p className="font-bold text-sm">
                        {recurringData?.displayValues?.patientName}
                      </p>
                      <div className="text-sm">{`${dayName} - ${monthName} ${dayNumber}, ${year}`}</div>
                      <div className="text-sm">{`${start} - ${end}`}</div>
                      <p className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 mt-2">
                        {`${recurringData?.displayValues?.treatmentName} with ${recurringData?.displayValues?.clinicianName}`}
                      </p>
                    </span>{' '}
                    <span className="flex items-center">
                      <input
                        type="checkbox"
                        className="absolute right-4 items-center h-4 w-4 text-primary-600 border-gray-300 rounded focus:ring-primary-500"
                        value={appt?.id}
                        checked={!!idsToBookAppointments[appt?.id]}
                        onChange={() => {
                          if (idsToBookAppointments[appt?.id]) {
                            setIdsToBookAppointments((prev) => {
                              const obj = { ...prev };
                              delete obj[appt?.id];
                              return obj;
                            });
                          } else {
                            setIdsToBookAppointments((prev) => ({
                              ...prev,
                              [appt?.id]: true,
                            }));
                          }
                        }}
                      />
                    </span>
                  </div>
                  {!!appt?.conflicts?.length && (
                    <div className="border-yellow-200 bg-yellow-100 p-4 rounded-lg mt-4">
                      <p className="font-bold">Schedule conflicts</p>
                      {appt?.conflicts?.map((conflict) => {
                        const dt = dayjs(convertToMS(conflict?.startTime)).tz(
                          selectedLocationFull?.timezone || 'America/New_York',
                        );
                        const start = dt.format('h:mm A');
                        const end = dt
                          .add(conflict?.duration || 0, 'minute')
                          .format('h:mm A');
                        return (
                          <div
                            className="flex flex-col"
                            key={appt.timestamp.start}
                          >
                            <p className="text-sm">{conflict?.patientName}</p>
                            <p className="text-sm">{`${conflict?.treatmentName}, ${start} - ${end}`}</p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            })}
          </form>
          {!!recurringData?.dataWithAllConflicts?.length && (
            <div className="space-x-3 flex justify-end mt-4">
              <Button
                text="Cancel"
                onClick={() => {
                  onClose();
                  setTimeout(() => {
                    setRecurringData({});
                  }, 500);
                }}
                color={ButtonColors.plain}
              />
              <Button
                text={
                  !Object.keys(idsToBookAppointments)?.length
                    ? 'Schedule appointments'
                    : `Schedule ${Object.keys(idsToBookAppointments)
                        ?.length} appointments`
                }
                onClick={
                  !appointment?.recurringAppointmentId
                    ? registerSubmit(onSubmit.bind(null, false), {
                        onSuccess,
                        onFail,
                      })
                    : registerSubmit(
                        onSubmitUpdateRecurring.bind(null, false),
                        {
                          onSuccess,
                          onFail,
                        },
                      )
                }
                loading={isSubmitting}
                disabled={!Object.keys(idsToBookAppointments)?.length}
              />
              {Number(timeStamp) < dayjs().valueOf() && (
                <Button
                  text={
                    !Object.keys(idsToBookAppointments)?.length
                      ? 'Schedule appointments and notify'
                      : `Schedule ${Object.keys(idsToBookAppointments)
                          ?.length} appointments and notify`
                  }
                  onClick={
                    !appointment?.recurringAppointmentId
                      ? registerSubmit(onSubmit.bind(null, true), {
                          onSuccess,
                          onFail,
                        })
                      : registerSubmit(
                          onSubmitUpdateRecurring.bind(null, true),
                          {
                            onSuccess,
                            onFail,
                          },
                        )
                  }
                  loading={isSubmitting}
                  disabled={!Object.keys(idsToBookAppointments)?.length}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ScheduleRecurringAppointments;
